<template>
  <div class="login-container">
    <img
      class="background"
      src="/login-background.svg"
      alt="brandwatch login background"
    >
    <div class="logo-container">
      <img
        src="/logo-card.svg"
        alt="brandwatch login logo"
      >
    </div>
    <div class="d-flex flex-row justify-center align-center login-form-container">
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onClickLogin"
      >
        <div class="d-flex justify-start">
          <h1 class="title">
            {{ $t('login.title') }}
          </h1>
        </div>
        <div class="d-flex justify-start">
          <h4 class="subtitle-1">
            {{ $t('login.subTitle') }}
          </h4>
        </div>
        <div class="d-flex align-center">
          <v-alert
            v-if="loginError"
            dense
            dismissible
            tile
            text
            color="grey darken-1"
            close-label="clear"
            icon="error"
            type="warning"
            elevation="0"
          >
            <small>Login failed - {{ loginError }}</small>
          </v-alert>
        </div>
        <r-text-field
          label=""
          class="input-text"
          :placeholder="$t('login.fields.username.placeholder')"
          :rules="[rules.required, rules.email]"
          @change="onUsernameInputChange"
        />

        <r-text-field
          label=""
          class="input-text"
          type="password"
          :placeholder="$t('login.fields.password.placeholder')"
          :rules="[rules.required, rules.min]"
          @change="onPasswordInputChange"
        />

        <r-button
          data-testid="login-button"
          class="px-3 py-0"
          elevation="0"
          type="submit"
          :label="$t('login.actions.signIn')"
          :disabled="loginFetching"
          :loading="loginFetching"
          @click="onClickLogin"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import RTextField from '@/components/library/molecules/RTextField'
import RButton from '@/components/library/atoms/RButton'

import { mapActions, mapGetters } from 'vuex'

const emailRegex = /\S+@\S+\.\S+/

export default {
  name: 'Login',
  components: {
    RButton,
    RTextField,
  },
  data: () => ({
    valid: false,
    username: '',
    password: '',
    showPassword: false,
    loginFetching: false,
    rules: {
      required: (v) => !!v || 'This field can not be blank',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      email: (v) => !!(v.match(emailRegex) || []).length || 'This must be a valid email address',
    },
  }),
  computed: mapGetters('users', {
    loginError: 'loginError',
  }),
  methods: {
    ...mapActions('users', [
      'login',
    ]),
    onPasswordInputChange({ value: password }) {
      this.password = password
    },
    onUsernameInputChange({ value: username }) {
      this.username = username
    },
    onClickLogin() {
      if (!this.$refs.form.validate() || this.loginFetching) {
        return
      }

      this.loginFetching = true
      const { username, password } = this
      this.login({ username, password }).then(() => {
        if (!this.loginError) {
          this.$router.push('/')
        }

        this.loginFetching = false
      })
    },
  },
}
</script>

<style scoped>
.v-application .grey--text.text--darken-1 .v-alert__icon {
  color: #fc4870 !important;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.login-container {
  height: 100%;
}

.login-form-container {
  height: 100%;
}

.title,.subtitle-1 {
  z-index: 1;
}

.title {
  font-weight: 500;
  font-size: 2.5rem !important;
  margin-top: 1rem;
  margin-bottom: 1.3rem;
  color: #3f3f3f;
}

.subtitle-1 {
  font-weight: 400;
  color: #3f3f3fbf;
  margin-bottom: 0.1rem;
}

/deep/.v-text-field--outlined > .v-input__control > .v-input__slot {
  width: 500px;
  background: white;
}

.logo-container {
  z-index: 1;
  position: absolute;
  margin-left: 2.25rem;
}

.input-text {
  margin-bottom: 10px;
}
</style>
